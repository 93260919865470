<template>
  <div>
    <table-page :config="config" />
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { majorStage } from '@/utils/public';

export default {
  name: "serviceItems",
  components: {
    TablePage
  },
  data() {
    return {
      config: {
        /* 表格是否可多选 */
        dataUrl: '/professionalCollegeRelation/list?collegeId=' + this.$route.query.id,
        belongTo: '当前院校：' + this.$route.query.other,
        filter: {
          initUrl: '/professional/getProfessionalType',
          controls: [
            {
              key: 'professionalClassificationManagementId',
              label: '分类',
              type: 'select',
              config: {
                options: 'data',
                textKey: 'professionalClassification',
                valueKey: 'id'
              }
            },
            {
              key: 'displayName',
              label: '显示名称',
              type: 'text'
            },
            {
              key: 'time',
              label: '申请截止日期',
              type: 'dateRange'
            },
          ]
        },
        columns: [
          {
            key: 'classificationName',
            title: '分类',
            width: '100px',
          },
          {
            key: 'professionalName',
            title: '专业名',
            width: '100px',
          },
          {
            key: 'displayName',
            title: '显示名称',
            width: '100px',
          },
          {
            key: 'displayEnglishName',
            title: '显示英文名',
            width: '100px',
          },
          {
            key: 'applyEndTime',
            title: '申请截止日期',
            width: '150px',
            type: 'datetime',
          },
          {
            key: 'gpa',
            title: 'GPA',
            width: '100px',
          },
          {
            key: 'toefl',
            title: '语言成绩',
            width: '100px',
            type: 'textCustomCol',
            config: {
              text: (val) => {
                return `托福${val.toefl ? val.toefl : '暂无'}/雅思${val.ielts ? val.ielts : '暂无'}`
              }
            }
          },

          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '80px' : '80px'
          }

        ],

        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        actions: [
          {
            key: 'edit',
            text: '新建专业',
            type: 'form',
            permission: '/professionalCollegeRelation/add',
            config: {
              color: 'primary',
              title: '新建专业',
              initUrl: '/professionalCollegeRelation/getProfessionalList?id=' + this.$route.query.id,
              submitUrl: "/professionalCollegeRelation/add",
              submitText: '保存',
              controls: [
                {
                  key: 'collegeLibraryManagementId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'stage',
                  label: '专业阶段',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: majorStage
                  }
                },
                {
                  key: 'professionalLibraryManagementId',
                  label: '专业',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'data',
                    textKey: 'professionalName',
                    valueKey: 'id'
                  }
                },
                {
                  key: 'displayName',
                  width: '130px',
                  label: '显示名称',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'displayEnglishName',
                  width: '130px',
                  label: '显示英文名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'tuition',
                  label: '学费（年）',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'applyOpenTime',
                  width: '130px',
                  label: '申请开放日期',
                  type: 'date',
                },
                {
                  key: 'applyEndTime',
                  width: '130px',
                  label: '申请截至日期',
                  type: 'date',
                },
                {
                  key: 'gpa',
                  label: 'GPA(4分制)',
                  type: 'text',
                  placeholder: '例.GPA3.9'
                },
                {
                  key: 'toefl',
                  label: '托福成绩',
                  type: 'text',
                  placeholder: '例.100'
                },
                {
                  key: 'ielts',
                  label: '雅思成绩',
                  type: 'text',
                  placeholder: '例.6.5'
                },
              ]
            }
          },
        ],
        /* 表格行操作 */
        rowActions: [
          {
            key: 'edit',
            text: '编辑',
            type: 'form',
            permission: '/professionalCollegeRelation/update',
            config: {
              color: 'primary',
              title: '编辑',
              initUrl: "/professionalCollegeRelation/update/get",
              submitUrl: "/professionalCollegeRelation/update",
              submitText: '保存',
              controls: [
                {
                  key: 'collegeLibraryManagementId',
                  label: '院校',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: this.$route.query.id,
                  config: {
                    options: [
                      {
                        value: this.$route.query.id,
                        text: this.$route.query.other,
                      }
                    ]
                  }
                },
                {
                  key: 'stage',
                  label: '专业阶段',
                  type: 'mSelect',
                  dealData: true,
                  required: true,
                  config: {
                    options: majorStage
                  }
                },
                {
                  key: 'professionalLibraryManagementId',
                  label: '专业',
                  type: 'souSelect',
                  required: true,
                  config: {
                    options: 'list',
                    textKey: 'professionalName',
                    valueKey: 'id'
                  }
                },
                {
                  key: 'displayName',
                  width: '130px',
                  label: '显示名称',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'displayEnglishName',
                  width: '130px',
                  label: '显示英文名',
                  type: 'text',
                  required: true,
                },
                {
                  key: 'tuition',
                  label: '学费（年）',
                  type: 'numeric',
                  placeholder: '例.500000',
                  config: {
                    format: 'currency'
                  }
                },
                {
                  key: 'applyOpenTime',
                  width: '130px',
                  label: '申请开放日期',
                  type: 'date',
                },
                {
                  key: 'applyEndTime',
                  width: '130px',
                  label: '申请截至日期',
                  type: 'date',
                },
                {
                  key: 'gpa',
                  label: 'GPA(4分制)',
                  type: 'text',
                  placeholder: '例.GPA3.9'
                },
                {
                  key: 'toefl',
                  label: '托福成绩',
                  type: 'text',
                  placeholder: '例.100'
                },
                {
                  key: 'ielts',
                  label: '雅思成绩',
                  type: 'text',
                  placeholder: '例.6.5'
                },
              ]
            }
          },
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/professionalCollegeRelation/delete',
            disabled: ({ selectedRows }) => {
              return true;
            },
            config: {
              color: 'danger',
              submitColor: 'danger',
              submitUrl: '/professionalCollegeRelation/delete'
            }
          },
        ]
      }
    }
  },
  async mounted() {
  },
  onUnmounted() {

  },
  computed: {

  },

  methods: {

  }
}
</script>

<style scoped lang="scss"></style>